.carouselContent .carouselContent__box,
.carouselContent .carouselContent__content {
  padding-bottom: 4.4vw;
}

@media screen and (min-width: 1220px) {
  .carouselContent .carouselContent__box,
  .carouselContent .carouselContent__content {
    padding-bottom: 44px;
  }
}
.carouselContent {
  margin-top: 0.8vw;
  margin: auto;
  max-width: 1320px;
}

@media screen and (min-width: 1220px) {
  .carouselContent {
    margin-top: 8px;
  }
}
.carouselContent__item {
  padding-top: 40px;
  position: relative;
}

.carouselContent__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .carouselContent__inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media (min-width: 769px) {
  .carouselContent .carouselContent__box {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 31%;
    flex: 0 0 31%;
    padding-left: 60px;
  }
}
.carouselContent .carouselContent__content {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

@media (min-width: 769px) and (max-width: 1200px) {
  .carouselContent .carouselContent__content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
  }
}
@media (min-width: 1201px) {
  .carouselContent .carouselContent__content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 24%;
    flex: 0 0 24%;
  }
}
@media (max-width: 1024px) {
  .carouselContent__imgCol {
    bottom: 0;
    opacity: 0.125;
    position: absolute;
    right: 0;
    z-index: -1;
  }
}
@media (min-width: 1025px) {
  .carouselContent__imgCol {
    position: relative;
  }
}
@media (min-width: 1025px) {
  .carouselContent__img {
    bottom: 0;
    left: 0;
    position: absolute;
  }
}
@media (max-width: 960) {
  .carouselNav {
    padding-left: 34px;
    padding-right: 34px;
  }
}
.carouselNav__box {
  text-align: center;
  border-bottom: 2px solid transparent;
  border-left: 1px solid transparent;
  cursor: pointer;
  padding: 10px;
  -webkit-transition: 300ms;
  transition: 300ms;
}

.carouselNav__box.slick-active {
  border-left-color: #e3e7ea;
}

.carouselNav__box[aria-hidden=true] + .carouselNav__box.slick-active {
  border-left-color: transparent;
}

.carouselNav__box:hover {
  border-bottom-color: #266dce;
}

.carouselNav__box.slick-current {
  background-color: #EBEEEF;
}

.carouselNav__img {
  margin: 0 auto 8px;
}

.carouselNav__title {
  color: #0c141b;
  font-size: 1.125em;
  text-align: center;
}

.carouselNav__subtitle {
  color: #266BC5;
  font-size: 1.125em;
  font-weight: 300;
}

.gridTable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
}

.gridTable h3 {
  padding-top: 0.5em;
}

.gridTable h3 {
  font-size: 20px;
}

@media screen and (min-width: 320px) {
  .gridTable h3 {
    font-size: calc(20px + 4 * (100vw - 320px) / 880);
  }
}
@media screen and (min-width: 1200px) {
  .gridTable h3 {
    font-size: 24px;
  }
}
.gridTable__wrapper {
  background: #e3e7ea;
  max-width: 75%;
  position: relative;
}

@media (max-width: 480px) {
  .gridTable__wrapper {
    max-width: 73%;
  }
}
@media (max-width: 320px) {
  .gridTable__wrapper {
    max-width: 67%;
  }
}
@media (max-width: 768px) and (min-width: 481px) {
  .gridTable__wrapper {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #e3e7ea), color-stop(50%, #fff));
    background: linear-gradient(90deg, #e3e7ea 50%, #fff 50%);
  }
}
@media (min-width: 769px) {
  .gridTable__wrapper {
    background: -webkit-gradient(linear, left top, right top, color-stop(33.33%, #e3e7ea), color-stop(33.33%, #fff), color-stop(66.66%, #fff), color-stop(66.66%, #e3e7ea));
    background: linear-gradient(90deg, #e3e7ea 33.33%, #fff 33.33%, #fff 66.66%, #e3e7ea 66.66%);
  }
}
.gridTable__col {
  width: 33%;
}

.gridTable__row {
  padding-bottom: 2vw;
  padding-top: 2vw;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #ccccce;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (min-width: 1220px) {
  .gridTable__row {
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 1220px) {
  .gridTable__row {
    padding-top: 20px;
  }
}
.gridTable__row:first-child {
  background: #fff;
  border: 0;
  padding: 0 10px 0;
  position: relative;
}

.gridTable__dots {
  bottom: 1em;
  left: 0;
  position: absolute;
}

.gridTable__inner {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.875em;
}

.gridTable__heading {
  font-weight: 900;
}

.gridTable__heading .gridTable__row {
  text-align: left;
}

.gridTable__img {
  background: #266dce;
  border: 2px solid #266dce;
  border-radius: 50%;
}

.gridTable__check {
  margin: auto;
}

.gridTable__nav {
  position: relative;
  z-index: 10;
}

@media (max-width: 480px) {
  .gridTable__nav {
    display: none;
  }
}
.gridTable__footer {
  background: #fff;
  border: 0;
  padding-bottom: 1.25em;
  padding-top: 1.25em;
}

.gridTable .slick-prev {
  left: 0;
}

.gridTable .slick-next {
  right: 0;
}

.slick-arrow {
  background: url(/ResourcePackages/Corporate/assets/src/images/icon-arrow-caret-green.svg) center center no-repeat transparent;
  border: 0;
  cursor: pointer;
  height: 21px;
  position: absolute;
  top: 1.33333em;
  width: 12px;
  z-index: 100;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.slick-dots .slick-active button {
  background-color: #ccccce;
  border-color: #ccccce;
}

.slick-dots button {
  color: transparent;
  cursor: pointer;
  font-size: 16px;
  background-color: #266dce;
  border: 2px solid #266dce;
  border-radius: 50%;
  height: 1.0625em;
  margin: 4px;
  outline: 0;
  width: 1.0625em;
  -webkit-transition: 400ms;
  transition: 400ms;
}

.slick-dots button:hover {
  background-color: #ccccce;
}

@media (max-width: 480px) {
  .slick-dots button {
    font-size: 12px;
    margin: 3px;
    border-width: 1px;
  }
}